.company-list-container{
  .company-details-row{
    padding: $spacing;

    .top-description{
      font-size: 14px;
      color: $text-gray;
    }

    .agents-row{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      margin-top: $spacing;
      gap: $spacing;

      .agent-box{
        padding: calc($spacing / 2) 0;
        border: 1px solid rgba($color: $black, $alpha: 0.2);
        background-color: $white;
        cursor: pointer;
        
        .icon{
          height: calc($spacing * 2);
          width: auto;

          path{
            fill: $primary-color;
          }
        }
        .title{
          margin-top: calc($spacing / 2);
        }

        &.active{
          background-color: $primary-color;

          .icon{
            path{
              fill: $white;
            }
          }

          .title{
            color: $white;
          }
        }
      }
    }

    .agent-details-row{
      display: grid;
      grid-template-columns: 1fr 2.2fr;
      gap: $spacing;
      margin-top: $spacing;

      .steps-container{
        background-color: rgba($color: $text-gray, $alpha: 0.1);
        padding: $spacing;
        border-radius: 5px;

        .step{
          gap: $spacing;
          margin-bottom: calc($spacing / 2);

          &:last-child{
            margin-bottom: 0;
          }

          .number-container{
            height: calc($spacing * 1.5);
            width: calc($spacing * 1.5);
            background-color: $primary-color;
            color: $white;
            margin-top: calc($spacing / 4);
            border-radius: 2px;
            box-shadow: 4px 4px 5px rgba($color: $primary-color, $alpha: 0.5);
          }

          .title{
            font-size: 18px;
            margin-bottom: calc($spacing / 4);
          }

          .source-code-pro{
            padding: 0 calc($spacing / 4);
            background-color: rgba($color: $primary-color, $alpha: 0.21);
          }
        }
      }

      .code-container{
        background-color: rgba($color: $text-gray, $alpha: 0.1);
        padding: $spacing;
        border-radius: 5px;

        .title{
          gap: calc($spacing / 4);
          font-size: 14px;
          color: $primary-color;
          margin-bottom: calc($spacing / 2);

          .download-icon{
            height: 16px;
            width: auto;

            path{
              fill : $primary-color
            }
          }
        }

        .code-box{
          padding: calc($spacing / 2);
          border-radius: 5px;
          background-color: rgba($color: $text-gray, $alpha: 0.1);
          font-size: 14px;
          overflow: hidden;
          height: 300px;

          pre {
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
        }

          &.open{
            height: auto;
          }

          .copy-box{
            position: absolute;
            height: calc($spacing * 1.5);
            width: calc($spacing * 1.5);
            background-color: $primary-color;
            top: 0;
            right: 0;
            border-bottom-left-radius: 5px;
            cursor: pointer;

            svg{
              height: 16px;
              width: auto;
              path{
                fill: $white;
              }
            }
          }
        }

        .open-row{
          background-color: $primary-color;
          height: calc($spacing * 1.5);
          border-radius: 0 0 5px 5px;
          cursor: pointer;

          svg{
            height: 16px;
            width: auto;

            path{
              fill : $white;
            }
          }

          &.open{
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}