.login-container {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  // background-image: url(../assets/images/login-bg-2.jpeg);
  // background-size: cover;
  // background-repeat: no-repeat;

  .login-form-container {
    background-color: rgba($color: $white, $alpha: 1);
    // backdrop-filter: blur(20px);
    padding: calc($spacing * 2);
    width: 450px;
    border-radius: 10px;

    .login-form {
      width: 100%;

      input {
        box-shadow: none;

        &:focus {
          border-color: $black;
        }
      }

      .logo-container {
        margin-bottom: calc($spacing * 2);
      }

      .password-container {
        margin-top: $spacing;
        margin-bottom: calc($spacing * 2);
      }
    }
  }
}