.dashboard-container{
  .top-filter-row{
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: $spacing;
    margin-bottom: calc($spacing * 2);
    
    .input-group{
      height: 40px;
    }

    .dropdown-row{
      display: grid;
      gap: $spacing;
      grid-template-columns: repeat(3, 1fr);

      .dropdown{
        color: #585353;
        font-size: 14px;
        background-color: $white;
        border-radius: 5px;
        padding: 0 calc($spacing / 2);
        height: 40px;
        // box-shadow: 0 1px 3px rgba($color: $black, $alpha: 0.09);
        cursor: pointer;

        .count{
          margin-left: calc($spacing / 2);
          padding: 0 calc($spacing / 4);
          min-width: 24px;
          border-radius: 5px;
          height: 16px;
          font-size: 10px;
          background-color: #D9D9D9;
          color: $black;
        }

        .caret-icon{
          width: 12px;
        }
      }
    }
  }

  .insights-row{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $spacing;
    margin-bottom: calc($spacing * 2);
    
    .insight-box{
      padding: $spacing;

      .number{
        font-size: 36px;
        line-height: 36px;
        margin-top: $spacing;
      }

      .label{
        font-size: 20px;
        color: $text-gray;
      }
    }
  }

  .charts-container{
    display: grid;
    grid-template-columns: 1.1fr 2fr;
    gap: $spacing;
    padding: $spacing;
    margin-bottom: calc($spacing * 2);

    .time-filter{
      padding: calc($spacing / 4) calc($spacing / 2);
      background-color: $body-bg;
      cursor: pointer;
      font-size: 14px;

      span{
        color: $primary-color;
      }
    }
  }

  .details-row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spacing;
    padding: $spacing;

    .paper-title{
      margin-bottom: $spacing;
    }

    .service-container{
      margin-bottom: calc($spacing / 4);
      padding: calc($spacing / 4) 0;
      position: relative;

      .status-container{
        width: 100px;
        font-size: 14px;
        padding-left: 4px;
      }

      .title{
        gap: $spacing;
        margin-bottom: calc($spacing / 4);
      }
      .description{
        font-size: 14px;
      }

      &::after{
        position: absolute;
        content: '';
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #8ae600;
      }
    }
  }
}