.pagination{
  background-color: $white;
  padding: 0 $spacing;
  margin-top: $spacing;
  height: calc($spacing * 2);
  gap: $spacing;

  .pagination-btn{
    height: calc($spacing * 1.25);
    width: calc($spacing * 1.25);
    border-radius: 50%;

    svg{
      path{
        fill: $white;
      }
    }
  }
}