.sidebar{
  background-color: $white;
  width: 250px;
  padding: $spacing 0;
  // border-right: 1px solid rgba($color: $black, $alpha: 0.12);
  
  .inner{
    position: sticky;
    top: calc($header-height + $spacing);
  }

  .sidebar-link{
    color: $black;
    text-decoration: none;
    height: 40px;
    padding: $spacing;
    gap: $spacing;
    cursor: pointer;
    position: relative;

    .icon{
      width: 24px;
      height: auto;
    }

    &:hover{
      text-decoration: none;
      background-color: rgba($color: $text-gray, $alpha: 0.1);
    }

    &::after{
      position: absolute;
      content: '';
      height: 30px;
      width: 4px;
      top: 5px;
      left: 0;
      background-color: $primary-color;
      opacity: 0;
    }

    &.active{
      background-color: $body-bg;
      &::after{
        opacity: 1;
      }

      .icon{
        path{
          fill: $primary-color;
          // stroke: $white;
        }
      }
    }
  }
}