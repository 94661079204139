header{
  height: $header-height;
  background-color: $white;
  padding: 0 $spacing;
  // box-shadow: 0 0 25px rgba($color: $black, $alpha: 0.12);
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.12);

  .input-group{
    border: 1px solid $black;
    border-radius: 12px;
    overflow: hidden;
    border-left: 0;
    max-width: 550px;

    input{
      border: none;
      color: $text-gray;
    }

    .input-group-text{
      border-radius: 0;

      &:first-child{
        border: 1px solid $primary-color;
      }
    }
  }

  .user-actions{
    gap: calc($spacing / 2);
    color: $text-gray;
    position: relative;
    cursor: pointer;

    img{
      height: 36px;
      width: 36px;
      border-radius: 50px;
    }

    svg{
      width: 12px;
      height: auto;
      path{
        fill: $text-gray;
      }
    }

    .dropdown-container{
      position: absolute;
      width: calc($spacing * 6);
      overflow: hidden;
      top: calc($spacing * 2);
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      .item{
        padding: calc($spacing / 2);
        cursor: pointer;

        &:hover{
          background-color: rgba($color: $text-gray, $alpha: 0.12);
        }
      }
      &.show{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}