.font-thin {
  font-weight: 100;
  font-style: normal;
}

.font-extralight {
  font-weight: 200;
  font-style: normal;
}

.font-light {
  font-weight: 300;
  font-style: normal;
}

.font-regular {
  font-weight: 400;
  font-style: normal;
}

.font-medium {
  font-weight: 500;
  font-style: normal;
}

.font-semibold {
  font-weight: 600;
  font-style: normal;
}

.font-bold {
  font-weight: 700;
  font-style: normal;
}

.font-extrabold {
  font-weight: 800;
  font-style: normal;
}

.font-black {
  font-weight: 900;
  font-style: normal;
}

.font-thin-italic {
  font-weight: 100;
  font-style: italic;
}

.font-extralight-italic {
  font-weight: 200;
  font-style: italic;
}

.font-light-italic {
  font-weight: 300;
  font-style: italic;
}

.font-regular-italic {
  font-weight: 400;
  font-style: italic;
}

.font-medium-italic {
  font-weight: 500;
  font-style: italic;
}

.font-semibold-italic {
  font-weight: 600;
  font-style: italic;
}

.font-bold-italic {
  font-weight: 700;
  font-style: italic;
}

.font-extrabold-italic {
  font-weight: 800;
  font-style: italic;
}

.font-black-italic {
  font-weight: 900;
  font-style: italic;
}